<script>
import { computed } from 'vue';

export default {
  name: 'kalio-base-input',
  props: {
    label: { type: String, default: null },
    description: { type: String, default: null },
    required: { type: Boolean, default: false },
    size: {
      type: String,
      default: 'base',
      validator: (value) => ['small', 'base'].includes(value),
    },
  },
  setup(props) {
    const labelClasses = computed(() => {
      const classes = {
        small: 'text-xs sm:text-base',
        base: 'text-sm sm:text-base',
      };

      return classes[props.size];
    });

    const descriptionClasses = computed(() => {
      const classes = {
        small: 'text-xs',
        big: 'text-sm',
      };

      return classes[props.size];
    });

    return {
      labelClasses,
      descriptionClasses,
    };
  },
};
</script>

<template>
  <div class="flex w-full flex-col text-left">
    <label
      v-if="!!label"
      class="mb-2 text-blue-gray-200"
      :class="labelClasses"
    >
      {{ label }}<span
        v-if="required"
        class="text-pv-light-blue"
      >&nbsp;*</span>
    </label>
    <span
      v-if="description"
      class="mb-2 text-blue-gray-400"
      :class="descriptionClasses"
      v-html="description"
    />
    <div class="text-sm leading-tight text-blue-gray-200 placeholder:text-blue-gray-400 sm:text-base">
      <slot name="input" />
    </div>
  </div>
</template>
