<script>
export default {
  name: 'csrf',
  data() {
    return { token: null };
  },
  mounted() {
    this.token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  },
};
</script>

<template>
  <input
    type="hidden"
    name="authenticity_token"
    :value="token"
  >
</template>
