<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { defineComponent, ref, watch, computed, toRefs } from 'vue';
import { useField } from 'vee-validate';
import KalioSpinner from '../shared/kalio-spinner.vue';
import KalioBaseInput from './kalio-base-input';

export default defineComponent({
  name: 'kalio-select-input',
  components: {
    KalioBaseInput,
    KalioSpinner,
    vSelect,
  },
  inheritAttrs: false,
  props: {
    name: { type: String, required: true },
    rules: { type: String, default: '' },
    modelValue: { type: [String, Object, Number, Boolean], default: null },
    value: { type: [String, Object, Number, Boolean], default: null },
    label: { type: String, default: '' },
    description: { type: String, default: '' },
    variant: {
      type: String,
      default: 'dark',
      validator: (value) => ['dark', 'semi-dark'].includes(value),
    },
    size: {
      type: String,
      default: 'base',
      validator: (value) => ['small', 'base'].includes(value),
    },
    absoluteError: { type: Boolean, default: false },
    displayError: { type: Boolean, default: true },
  },
  setup(props) {
    const { name, rules } = toRefs(props);
    const {
      errorMessage,
      value: inputValue,
    } = useField(name, rules, { initialValue: props.modelValue ?? props.value });

    const selected = ref(props.modelValue ?? props.value);
    watch(inputValue, () => {
      selected.value = inputValue.value;
    });

    const required = computed(() => props.rules.includes('required'));

    const containerClasses = computed(() => {
      const colorClasses = {
        dark: 'bg-pv-blue-900',
        'semi-dark': 'bg-pv-blue-700',
      };
      const noErrorColorClasses = {
        dark: 'border-blue-gray-700 focus-within:ring-pv-light-blue focus-within:border-pv-light-blue ',
        'semi-dark': 'border-blue-gray-600 focus-within:ring-pv-light-blue focus-within:border-pv-light-blue ',
      };
      const errorColorClasses = 'border-red-600';

      const sizeClasses = {
        small: 'border',
        base: 'border-2 p-0.5 sm:p-1',
      };

      return `${sizeClasses[props.size]} ${colorClasses[props.variant]} ${errorMessage.value ?
        errorColorClasses : noErrorColorClasses[props.variant]}`;
    });

    const iconsScale = computed(() => {
      const classes = {
        small: 'scale(.9)',
        big: 'scale(1)',
      };

      return classes[props.size];
    });

    return {
      errorMessage,
      inputValue,
      selected,
      required,
      containerClasses,
      iconsScale,
    };
  },
});
</script>

<template>
  <kalio-base-input
    :label="label"
    :description="description"
    :required="required"
    :size="size"
  >
    <template #input>
      <div
        class="flex flex-col"
        :class="{ 'relative': absoluteError }"
      >
        <div
          class="rounded-md"
          :class="containerClasses"
        >
          <v-select
            v-bind="$attrs"
            v-model="inputValue"
            :name="name"
            class="cursor-pointer"
          >
            <template #spinner="{ loading }">
              <kalio-spinner
                v-if="loading"
                :size="5"
                class="text-pv-light-blue"
              />
            </template>
          </v-select>
          <input
            v-model="selected"
            type="hidden"
            :name="name"
          >
        </div>
        <span
          v-if="errorMessage && displayError"
          class="text-sm text-red-600"
          :class="absoluteError ? 'absolute -bottom-6 left-0' : 'mt-0.5'"
        >
          {{ errorMessage }}
        </span>
      </div>
    </template>
  </kalio-base-input>
</template>

<style scoped>
:deep() {
  --vs-border-width: 0px;
}

:deep(.vs__selected) {
  @apply text-blue-gray-200 leading-normal;
}

:deep(.vs--multiple .vs__selected) {
  @apply rounded-full bg-opacity-20 bg-pv-light-blue border-pv-light-blue text-pv-light-blue px-2;
}

:deep(.vs__search) {
  @apply text-blue-gray-200 leading-normal;
}

:deep(.vs__deselect) {
  @apply ml-1.5 fill-current text-pv-light-blue;
}

:deep(.vs__dropdown-menu) {
  @apply bg-blue-gray-900 text-blue-gray-400;
}

:deep(.vs__dropdown-option--highlight) {
  @apply bg-pv-light-blue;
}

:deep(.vs__dropdown-option--selected) {
  @apply bg-pv-light-blue;
}

:deep(.vs__open-indicator) {
  @apply fill-current scale-95 text-blue-gray-400;
  transform: v-bind(iconsScale);
}

:deep(.vs__clear svg) {
  @apply fill-current text-blue-gray-400;
  transform: v-bind(iconsScale);
}
</style>
