<script>
import { ref, computed } from 'vue';
import useFormAggregator from '../../hooks/form-aggregator';
import Csrf from '../shared/csrf.vue';

export default {
  name: 'kalio-form-wrapper',
  components: { Csrf },
  inheritAttrs: false,
  props: {
    method: { type: String, default: 'post' },
    allowInvalidSubmit: { type: Boolean, default: false },
  },
  setup(props, { attrs, emit }) {
    const { validate } = useFormAggregator();
    const loading = ref(false);
    const form = ref(null);

    function submit() {
      validate({ mode: props.allowInvalidSubmit ? 'silent' : 'force' }).then(({ valid }) => {
        if (!valid && !props.allowInvalidSubmit) return;

        if (attrs.onSubmit) {
          // eslint-disable-next-line vue/require-explicit-emits
          emit('submit');
        } else {
          loading.value = true;
          form.value.submit();
        }
      });
    }

    const filteredAttrs = computed(
      () => Object.keys(attrs)
        .filter(key => key !== 'onSubmit')
        .reduce((obj, key) => {
          obj[key] = attrs[key];

          return obj;
        }, {}),
    );

    return {
      form,
      submit,
      loading,
      filteredAttrs,
    };
  },
};
</script>

<template>
  <form
    ref="form"
    method="post"
    v-bind="filteredAttrs"
    @submit.prevent="submit"
  >
    <csrf />
    <input
      type="hidden"
      name="_method"
      :value="method"
    >
    <slot :loading="loading" />
  </form>
</template>
