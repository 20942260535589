import axios from 'axios';
import { useField } from 'vee-validate';
import { computed, onMounted, getCurrentInstance } from 'vue';

export default function useFile({ inputRef, name, rules = null, src = null, initialValue = null }) {
  const {
    value: inputValue,
    errorMessage,
    validate,
  } = useField(name, rules, {
    type: 'file',
    initialValue,
  });

  const { emit } = getCurrentInstance();

  function saveFile(data) {
    inputValue.value = data;
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(data);
    inputRef.value.files = dataTransfer.files;
  }

  function handleChange(event) {
    if (event.target.files.length === 0) return;

    saveFile(event.target.files[0]);
    emit('update:modelValue', event.target.files[0]);
    validate();
  }
  function handleDrop(event) {
    saveFile(event.dataTransfer.files[0]);
    emit('update:modelValue', event.dataTransfer.files[0]);
    validate();
  }
  function reset() {
    inputValue.value = null;
    inputRef.value.value = '';
    validate();
  }

  onMounted(() => {
    if (src) {
      axios.get(src, { responseType: 'blob' }).then(response => {
        const data = new File([response.data], response.config.url);
        saveFile(data);
      });
    }
  });

  const extensions = computed(() => {
    const extensionRule = rules.value.split('|').find(rule => rule.startsWith('ext:'));

    return extensionRule?.replaceAll('ext:', '').replaceAll(',', ', ');
  });

  const sizeLimit = computed(() => {
    const sizeRule = rules.value.split('|').find(rule => rule.startsWith('size:'));
    const megabyteToKilobyteRatio = 1000;

    return Math.floor(parseInt(sizeRule?.replaceAll('size:', ''), 10) / megabyteToKilobyteRatio);
  });

  const fileName = computed(() => {
    if (!inputValue.value) return undefined;

    return inputValue.value.name.split('/').pop();
  });

  return {
    inputValue,
    errorMessage,
    handleChange,
    handleDrop,
    reset,
    extensions,
    sizeLimit,
    fileName,
  };
}
