<script setup>
import { inject } from 'vue';
import { flashMessagesKey } from '@/utils/keys.js';
import KalioFlashAlert from '@/components/shared/kalio-flash-alert.vue';

const flashMessages = inject(flashMessagesKey);
</script>

<template>
  <div class="flex w-full flex-col">
    <kalio-flash-alert
      v-for="flashMessage in flashMessages"
      :key="flashMessage.id"
      :flash="[flashMessage.type, flashMessage.text]"
      class="mb-2 last:mb-8"
    />
  </div>
</template>
