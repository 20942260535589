<script>
import { defineComponent, ref, toRefs, inject } from 'vue';
import useFile from '../../hooks/file';
import { screenKey } from '../../utils/keys.js';
import KalioBaseInput from './kalio-base-input';

export default defineComponent({
  name: 'kalio-file-input',
  components: {
    KalioBaseInput,
  },
  inheritAttrs: false,
  props: {
    name: { type: String, required: true },
    label: { type: String, default: '' },
    description: { type: String, default: '' },
    rules: { type: String, default: 'ext:jpg,jpeg,png' },
    src: { type: String, default: null },
  },
  setup(props) {
    const inputRef = ref(null);
    const { name, rules } = toRefs(props);
    const {
      inputValue,
      errorMessage,
      handleChange,
      handleDrop,
      reset,
      extensions,
      fileName,
      sizeLimit,
    } = useFile({ inputRef, name, rules, src: props.src });

    const screen = inject(screenKey);

    return {
      inputValue,
      errorMessage,
      handleChange,
      handleDrop,
      reset,
      extensions,
      fileName,
      sizeLimit,
      inputRef,
      screen,
    };
  },
});
</script>

<template>
  <kalio-base-input
    :label="label"
    :description="description"
  >
    <template #input>
      <div class="relative w-full rounded-xl bg-pv-blue-900 p-4">
        <button
          v-if="inputValue"
          class="absolute right-1 top-1 items-center justify-center rounded-full bg-pv-yellow p-1 focus:outline-none"
          @click="reset"
        >
          <inline-svg
            :src="require('assets/images/icons/close.svg')"
            class="h-auto w-6 fill-current text-black"
          />
        </button>
        <label
          class="flex cursor-pointer items-center justify-center rounded-md border-2 border-dashed border-blue-gray-500 p-4"
          @dragenter.prevent
          @dragover.prevent
          @drop.prevent="handleDrop"
        >
          <input
            v-bind="$attrs"
            ref="inputRef"
            type="file"
            :name="name"
            class="hidden"
            @change="handleChange"
          >
          <div class="flex flex-col items-center justify-center space-y-3">
            <inline-svg
              :src="require('assets/images/icons/file-upload.svg')"
              class="h-auto w-10 fill-current text-blue-gray-400"
            />
            <span
              v-if="inputValue"
              class="flex h-8 w-full items-center justify-center break-all text-center sm:h-12"
            >
              {{ fileName }}
            </span>
            <div
              v-else
              class="flex h-8 flex-col items-center justify-center sm:h-12"
            >
              <p class="text-pv-yellow">
                {{ $t('form.file.upload') }}
                <span
                  v-if="!screen.isMobile"
                  class="text-white"
                >
                  {{ $t('form.file.orDrag') }}
                </span>
              </p>
              <div v-if="extensions || sizeLimit">
                <span
                  v-if="extensions"
                  class="uppercase"
                >
                  {{ extensions }}
                </span>
                <span v-if="sizeLimit">
                  {{ $t('form.file.upTo') }} {{ sizeLimit }} MB
                </span>
              </div>
            </div>
          </div>
        </label>
      </div>
      <span
        v-if="errorMessage"
        class="text-sm text-red-600"
      >
        {{ errorMessage }}
      </span>
    </template>
  </kalio-base-input>
</template>
