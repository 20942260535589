import { useForm } from 'vee-validate';
import { ref, computed, provide } from 'vue';

export default function useFormAggregator() {
  const { validate: validateSingleForm } = useForm();

  const forms = ref([]);

  function registerForm(form) {
    forms.value.push(form);
  }

  provide('registerForm', registerForm);

  async function validate(opts = {}) {
    if (forms.value.length > 0) {
      const validationResults = await Promise.all(
        forms.value.map(form => form.validate(opts)),
      );

      return validationResults.map(result => result.valid).includes(false);
    }

    return validateSingleForm(opts);
  }

  const aggregatedMetas = computed(() => true);

  const aggregatedErrors = computed(() => true);

  return {
    validate,
    aggregatedMetas,
    aggregatedErrors,
  };
}
